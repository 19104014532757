/**
 * 立即购买的静态数据
 * timtit：按时长购买头
 * timdata：按时长购买数据
 * licensetit：按license购买头
 * licensedata：按时长购买数据
 * severPacktit：服务包头
 * severPackdata：服务包数据
 * severData：服务产品购买
 *
 * 案例详情: successfulDetail
 */

import stars_t from '@/assets/imgs/buying/stars_t.png';
import stars_y from '@/assets/imgs/buying/stars_y.png';
import stars_j from '@/assets/imgs/buying/stars_j.png';
import stars_s from '@/assets/imgs/buying/stars_s.png';
import stars_z from '@/assets/imgs/buying/stars_z.png';
import serveImg01_1 from '@/assets/imgs/buying/serveImg01-1.png';
import serveImg02_2 from '@/assets/imgs/buying/serveImg02-2.png';
import serveImg01 from '@/assets/imgs/buying/serveImg01.png';
import serveImg02 from '@/assets/imgs/buying/serveImg02.png';
import serveImg03 from '@/assets/imgs/buying/serveImg03.png';
import serveImg04 from '@/assets/imgs/buying/serveImg04.png';
import serveImg05 from '@/assets/imgs/buying/serveImg05.png';
import serveImg06 from '@/assets/imgs/buying/serveImg06.png';
import serveImg07 from '@/assets/imgs/buying/serveImg07.png';
import serveImg08 from '@/assets/imgs/buying/serveImg08.png';
import serveImg09 from '@/assets/imgs/buying/serveImg09.png';
import serveImg10 from '@/assets/imgs/buying/serveImg10.png';
import serveImg11 from '@/assets/imgs/buying/serveImg11.png';
import serveImg12 from '@/assets/imgs/buying/serveImg12.png';

import successfulDetail_0_1 from '@/assets/imgs/successful/detail/successfulDetail_0_1.png';
import successfulDetail_0_2 from '@/assets/imgs/successful/detail/successfulDetail_0_2.png';
import successfulDetail_0_3 from '@/assets/imgs/successful/detail/successfulDetail_0_3.png';
import successfulDetail_0_4 from '@/assets/imgs/successful/detail/successfulDetail_0_4.png';
import successfulDetail_0_5 from '@/assets/imgs/successful/detail/successfulDetail_0_5.png';
import successfulDetail_1_1 from '@/assets/imgs/successful/detail/successfulDetail_1_1.jpg';
import successfulDetail_1_2 from '@/assets/imgs/successful/detail/successfulDetail_1_2.jpg';
import successfulDetail_1_3 from '@/assets/imgs/successful/detail/successfulDetail_1_3.jpg';
import successfulDetail_1_4 from '@/assets/imgs/successful/detail/successfulDetail_1_4.jpg';
import successfulDetail_1_5 from '@/assets/imgs/successful/detail/successfulDetail_1_5.jpg';
import successfulDetail_2_1 from '@/assets/imgs/successful/detail/successfulDetail_2_1.jpg';
import successfulDetail_2_2 from '@/assets/imgs/successful/detail/successfulDetail_2_2.jpg';
import successfulDetail_2_3 from '@/assets/imgs/successful/detail/successfulDetail_2_3.jpg';
import successfulDetail_2_4 from '@/assets/imgs/successful/detail/successfulDetail_2_4.png';
import successfulDetail_2_5 from '@/assets/imgs/successful/detail/successfulDetail_2_5.png';
import successfulDetail_3_1 from '@/assets/imgs/successful/detail/successfulDetail_3_1.png';
import successfulDetail_3_2 from '@/assets/imgs/successful/detail/successfulDetail_3_2.png';
import successfulDetail_3_3 from '@/assets/imgs/successful/detail/successfulDetail_3_3.png';
import successfulDetail_3_4 from '@/assets/imgs/successful/detail/successfulDetail_3_4.png';
import successfulDetail_3_5 from '@/assets/imgs/successful/detail/successfulDetail_3_5.png';
import successfulDetail_3_6 from '@/assets/imgs/successful/detail/successfulDetail_3_6.png';
import successfulDetail_3_7 from '@/assets/imgs/successful/detail/successfulDetail_3_7.png';
import successfulDetail_3_8 from '@/assets/imgs/successful/detail/successfulDetail_3_8.png';
import successfulDetail_4_1 from '@/assets/imgs/successful/detail/successfulDetail_4_1.jpg';
import successfulDetail_4_2 from '@/assets/imgs/successful/detail/successfulDetail_4_2.png';
import successfulDetail_4_3 from '@/assets/imgs/successful/detail/successfulDetail_4_3.png';
import successfulDetail_4_4 from '@/assets/imgs/successful/detail/successfulDetail_4_4.jpg';
import successfulDetail_4_5 from '@/assets/imgs/successful/detail/successfulDetail_4_5.png';
import successfulDetail_4_6 from '@/assets/imgs/successful/detail/successfulDetail_4_6.jpg';
import successfulDetail_4_7 from '@/assets/imgs/successful/detail/successfulDetail_4_7.jpg';
import successfulDetail_4_8 from '@/assets/imgs/successful/detail/successfulDetail_4_8.jpg';
import successfulDetail_4_9 from '@/assets/imgs/successful/detail/successfulDetail_4_9.jpg';
import successfulDetail_4_10 from '@/assets/imgs/successful/detail/successfulDetail_4_10.jpg';
import successfulDetail_4_11 from '@/assets/imgs/successful/detail/successfulDetail_4_11.jpg';
import successfulDetail_4_12 from '@/assets/imgs/successful/detail/successfulDetail_4_12.jpg';
import successfulDetail_4_13 from '@/assets/imgs/successful/detail/successfulDetail_4_13.jpg';
import successfulDetail_5_1 from '@/assets/imgs/successful/detail/successfulDetail_5_1.jpg';

const mock = {
  buying: {
    timtit: [
      {
        id: 0,
        name: '分类',
        style: {
          width: '120px',
          color: '#303336',
          backgroundColor: '#F8F8F8',
          border: '1px solid #EFEFEF',
          borderBottom: '1px solid #EFEFEF',
        },
      },
      {
        id: 1,
        name: '入门型 T9',
        style: {
          width: '225px',
          color: '#fff',
          backgroundColor: '#ffaa58',
          border: '1px solid #EFEFEF',
        },
      },
      {
        id: 2,
        name: '办公型 T9',
        style: {
          width: '225px',
          color: '#fff',
          backgroundColor: '#ffaa58',
          border: '1px solid #EFEFEF',
        },
      },
      {
        id: 3,
        name: '办公增强型 T9',
        style: {
          width: '225px',
          color: '#fff',
          backgroundColor: '#ffaa58',
          border: '1px solid #EFEFEF',
        },
      },
      {
        id: 4,
        name: '集团型 T9',
        style: {
          width: '225px',
          color: '#fff',
          backgroundColor: '#ffaa58',
          border: '1px solid #EFEFEF',
        },
      },
      {
        id: 5,
        name: '集团增强型 T9',
        style: {
          width: '225px',
          color: '#fff',
          backgroundColor: '#ffaa58',
          border: '1px solid #EFEFEF',
        },
      },
      // {
      //   id: 6,
      //   name: '开源型 T9',
      //   style: {
      //     width: '180px',
      //     color: '#fff',
      //     backgroundColor: '#ffaa58',
      //     border: '1px solid #EFEFEF'
      //   }
      // }
    ],
    licensetit: [
      {
        id: 0,
        name: '分类',
        style: {
          width: '120px',
          color: '#303336',
          backgroundColor: '#F8F8F8',
          border: '1px solid #EFEFEF',
          borderBottom: '1px solid #EFEFEF',
        },
      },
      {
        id: 1,
        name: '入门型 L8',
        style: {
          width: '225px',
          color: '#fff',
          backgroundColor: '#ffaa58',
          border: '1px solid #EFEFEF',
        },
      },
      {
        id: 2,
        name: '办公型 L8',
        style: {
          width: '225px',
          color: '#fff',
          backgroundColor: '#ffaa58',
          border: '1px solid #EFEFEF',
        },
      },
      {
        id: 3,
        name: '办公增强型 L8',
        style: {
          width: '225px',
          color: '#fff',
          backgroundColor: '#ffaa58',
          border: '1px solid #EFEFEF',
        },
      },
      {
        id: 4,
        name: '集团型 L8',
        style: {
          width: '225px',
          color: '#fff',
          backgroundColor: '#ffaa58',
          border: '1px solid #EFEFEF',
        },
      },
      {
        id: 5,
        name: '集团增强型 L8',
        style: {
          width: '225px',
          color: '#fff',
          backgroundColor: '#ffaa58',
          border: '1px solid #EFEFEF',
        },
      },
    ],
    severPacktit: [
      {
        id: 0,
        name: '分类',
        style: {
          width: '200px',
          color: '#303336',
          backgroundColor: '#F8F8F8',
          border: '1px solid #EFEFEF',
          borderBottom: '1px solid #EFEFEF',
        },
      },
      {
        id: 1,
        name: '铜牌服务包',
        img: stars_t,
        style: {
          fontSize: '18px',
          fontWeight: 'bold',
          width: '225px',
          color: 'rgba(229,144,118,1)',
          backgroundColor: '#F8F8F8',
          border: '1px solid #EFEFEF',
        },
      },
      {
        id: 2,
        name: '银牌服务包',
        img: stars_y,
        style: {
          width: '225px',
          color: 'rgba(131,164,187,1)',
          backgroundColor: '#F8F8F8',
          border: '1px solid #EFEFEF',
        },
      },
      {
        id: 3,
        name: '金牌服务包',
        img: stars_j,
        style: {
          width: '225px',
          color: 'rgba(255,183,0,1)',
          backgroundColor: '#F8F8F8',
          border: '1px solid #EFEFEF',
        },
      },
      {
        id: 4,
        name: '钻石服务包',
        img: stars_s,
        style: {
          width: '225px',
          color: 'rgba(0,178,220,1)',
          backgroundColor: '#F8F8F8',
          border: '1px solid #EFEFEF',
        },
      },
      {
        id: 5,
        name: '战略服务包',
        img: stars_z,
        style: {
          width: '225px',
          color: 'rgba(40,148,253,1)',
          backgroundColor: '#F8F8F8',
          border: '1px solid #EFEFEF',
        },
      },
    ],
    timdata: {
      a: [
        {
          id: 0,
          name: '可建表',
          style: {
            width: '120px',
            color: '#303336',
            backgroundColor: '#F8F8F8',
          },
        },
        {
          id: 1,
          name: '15',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 2,
          name: '30',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 3,
          name: '100',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 4,
          name: '不限',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 5,
          name: '不限',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        // {
        //   id: 6,
        //   name: '不限',
        //   style: {
        //     width: '180px',
        //     color: '#000',
        //     backgroundColor: 'rgba(255,255,255,1)'
        //   }
        // }
      ],
      b: [
        {
          id: 0,
          name: '可建功能',
          style: {
            width: '120px',
            color: '#303336',
            backgroundColor: '#F8F8F8',
          },
        },
        {
          id: 1,
          name: '30',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 2,
          name: '60',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 3,
          name: '300',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 4,
          name: '不限',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 5,
          name: '不限',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        // {
        //   id: 6,
        //   name: '不限',
        //   style: {
        //     width: '180px',
        //     color: '#000',
        //     backgroundColor: 'rgba(255,255,255,1)'
        //   }
        // }
      ],
      c: [
        {
          id: 0,
          name: '可建流程',
          style: {
            width: '120px',
            color: '#303336',
            backgroundColor: '#F8F8F8',
          },
        },
        {
          id: 1,
          name: '10',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 2,
          name: '30',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 3,
          name: '100',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 4,
          name: '不限',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 5,
          name: '不限',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        // {
        //   id: 6,
        //   name: '不限',
        //   style: {
        //     width: '180px',
        //     color: '#000',
        //     backgroundColor: 'rgba(255,255,255,1)'
        //   }
        // }
      ],
      d: [
        {
          id: 0,
          name: '使用人数',
          style: {
            width: '120px',
            color: '#303336',
            backgroundColor: '#F8F8F8',
          },
        },
        {
          id: 1,
          name: '10',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 2,
          name: '100',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 3,
          name: '600',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 4,
          name: '2000',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 5,
          name: '不限',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        // {
        //   id: 6,
        //   name: '不限',
        //   style: {
        //     width: '180px',
        //     color: '#000',
        //     backgroundColor: 'rgba(255,255,255,1)'
        //   }
        // }
      ],
      e: [
        {
          id: 0,
          name: '数据库',
          style: {
            width: '120px',
            height: '100px',
            color: '#303336',
            backgroundColor: '#F8F8F8',
          },
        },
        {
          id: 1,
          name: 'mysql',
          style: {
            width: '225px',
            height: '100px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 2,
          name: 'mysql',
          style: {
            width: '225px',
            height: '100px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 3,
          name: 'mysql、SqlServer、Oracle',
          style: {
            width: '225px',
            height: '100px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 4,
          name: 'mysql、SqlServer、Oracle、神通、巨衫',
          style: {
            width: '225px',
            height: '100px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 5,
          name: 'mysql、SqlServer、Oracle、神通、巨衫',
          style: {
            width: '225px',
            height: '100px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        // {
        //   id: 6,
        //   name: 'mysql、SqlServer、Oracle、神通、巨衫、其他指定',
        //   style: {
        //     width: '180px',
        //     height: '100px',
        //     color: '#000',
        //     backgroundColor: 'rgba(255,255,255,1)'
        //   }
        // }
      ],
      f: [
        {
          id: 0,
          name: '部署方式',
          style: {
            width: '120px',
            color: '#303336',
            backgroundColor: '#F8F8F8',
          },
        },
        {
          id: 1,
          name: '普通',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 2,
          name: '普通',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 3,
          name: '普通',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 4,
          name: '可集群',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 5,
          name: '可集群',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        // {
        //   id: 6,
        //   name: '可集群',
        //   style: {
        //     width: '180px',
        //     color: '#000',
        //     backgroundColor: 'rgba(255,255,255,1)'
        //   }
        // }
      ],
      h: [
        {
          id: 0,
          name: '场景',
          style: {
            width: '120px',
            height: '100px',
            color: '#303336',
            backgroundColor: '#F8F8F8',
          },
        },
        {
          id: 1,
          name: '小微企业/门店/学校/部门内部日常办公工具搭建',
          style: {
            width: '225px',
            height: '100px',
            color: '#000',
            paddingShow: true,
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 2,
          name: '企业中型系统搭建(采购系统、考试系统、报销系统等)',
          style: {
            width: '225px',
            height: '100px',
            color: '#000',
            paddingShow: true,
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 3,
          name: ' 中型企业信息化建设',
          style: {
            width: '225px',
            height: '100px',
            color: '#000',
            paddingShow: true,
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 4,
          name: '中大型企业阶段性信息化建设',
          style: {
            width: '225px',
            height: '100px',
            color: '#000',
            paddingShow: true,
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 5,
          name: '中大型企业战略性信息化建设',
          style: {
            width: '225px',
            height: '100px',
            color: '#000',
            paddingShow: true,
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        // {
        //   id: 6,
        //   name: '有中台战略性需求的大型企业',
        //   style: {
        //     width: '180px',
        //     height: '100px',
        //     color: '#000',
        //     paddingShow: true,
        //     backgroundColor: 'rgba(255,255,255,1)'
        //   }
        // }
      ],
      // i: [
      //   {
      //     id: 0,
      //     name: '开源率',
      //     style: {
      //       width: '120px',
      //       color: '#303336',
      //       backgroundColor: '#F8F8F8'
      //     }
      //   },
      //   {
      //     id: 1,
      //     name: '0%',
      //     style: {
      //       width: '180px',
      //       color: '#000',
      //       backgroundColor: 'rgba(255,255,255,1)'
      //     }
      //   },
      //   {
      //     id: 2,
      //     name: '0%',
      //     style: {
      //       width: '180px',
      //       color: '#000',
      //       backgroundColor: 'rgba(255,255,255,1)'
      //     }
      //   },
      //   {
      //     id: 3,
      //     name: '0%',
      //     style: {
      //       width: '180px',
      //       color: '#000',
      //       backgroundColor: 'rgba(255,255,255,1)'
      //     }
      //   },
      //   {
      //     id: 4,
      //     name: '30%',
      //     style: {
      //       width: '180px',
      //       color: '#000',
      //       backgroundColor: 'rgba(255,255,255,1)'
      //     }
      //   },
      //   {
      //     id: 5,
      //     name: '70%',
      //     style: {
      //       width: '180px',
      //       color: '#000',
      //       backgroundColor: 'rgba(255,255,255,1)'
      //     }
      //   },
      //   {
      //     id: 6,
      //     name: '100%',
      //     style: {
      //       width: '180px',
      //       color: '#000',
      //       backgroundColor: 'rgba(255,255,255,1)'
      //     }
      //   }
      // ],
      j: [
        {
          id: 0,
          name: '服务',
          style: {
            width: '120px',
            color: '#303336',
            backgroundColor: '#F8F8F8',
          },
        },
        {
          id: 1,
          img: stars_j,
          name: '金牌服务包',
          sty: {
            fontSize: '18px',
            fontWeight: 'bold',
            color: 'rgba(255,183,0,1)',
          },
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 2,
          img: stars_j,
          name: '金牌服务包',
          sty: {
            fontSize: '18px',
            fontWeight: 'bold',
            color: 'rgba(255,183,0,1)',
          },
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 3,
          img: stars_j,
          name: '金牌服务包',
          sty: {
            fontSize: '18px',
            fontWeight: 'bold',
            color: 'rgba(255,183,0,1)',
          },
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 4,
          img: stars_s,
          name: '钻石服务包',
          sty: {
            fontSize: '18px',
            fontWeight: 'bold',
            color: 'rgba(0,178,220,1)',
          },
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 5,
          img: stars_s,
          name: '钻石服务包',
          sty: {
            fontSize: '18px',
            fontWeight: 'bold',
            color: 'rgba(0,178,220,1)',
          },
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        // {
        //   id: 6,
        //   img: stars_z,
        //   name: '战略服务包',
        //   sty: {
        //     fontSize: '18px',
        //     fontWeight: 'bold',
        //     color: 'rgba(40,148,253,1)',
        //   },
        //   style: {
        //     width: '180px',
        //     color: '#000',
        //     backgroundColor: 'rgba(255,255,255,1)'
        //   }
        // }
      ],
      k: [
        {
          id: 0,
          name: '服务期限',
          style: {
            width: '120px',
            color: '#303336',
            backgroundColor: '#F8F8F8',
          },
        },
        {
          id: 1,
          name: '12月',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 2,
          name: '12月',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 3,
          name: '12月',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 4,
          name: '12月',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 5,
          name: '12月',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        // {
        //   id: 6,
        //   name: '24月',
        //   style: {
        //     width: '180px',
        //     color: '#000',
        //     backgroundColor: 'rgba(255,255,255,1)'
        //   }
        // }
      ],
      l: [
        {
          id: 0,
          name: '每年可用License数量',
          style: {
            width: '120px',
            color: '#303336',
            backgroundColor: '#F8F8F8',
          },
        },
        {
          id: 1,
          name: '20个',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 2,
          name: '20个',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 3,
          name: '20个',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 4,
          name: '30个',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 5,
          name: '40个',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        // {
        //   id: 6,
        //   name: '不限',
        //   style: {
        //     width: '180px',
        //     color: '#000',
        //     backgroundColor: 'rgba(255,255,255,1)'
        //   }
        // }
      ],
      m: [
        {
          id: 0,
          name: '首年售价',
          style: {
            width: '120px',
            color: '#303336',
            backgroundColor: '#F8F8F8',
          },
        },
        {
          id: 1,
          name: '11900',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 2,
          name: '咨询',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 3,
          name: '咨询',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 4,
          name: '咨询',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 5,
          name: '咨询',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        // {
        //   id: 6,
        //   name: '咨询',
        //   style: {
        //     width: '180px',
        //     color: '#000',
        //     backgroundColor: 'rgba(255,255,255,1)'
        //   }
        // }
      ],
      n: [
        {
          id: 0,
          name: '以后每年费用（按需付费）',
          style: {
            width: '120px',
            color: '#303336',
            backgroundColor: '#F8F8F8',
          },
        },
        {
          id: 1,
          name: '10000',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 2,
          name: '咨询',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 3,
          name: '咨询',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 4,
          name: '咨询',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 5,
          name: '咨询',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        // {
        //   id: 6,
        //   name: '咨询',
        //   style: {
        //     width: '180px',
        //     color: '#000',
        //     backgroundColor: 'rgba(255,255,255,1)'
        //   }
        // }
      ],
    },
    licensedata: {
      a: [
        {
          id: 0,
          name: '可建表',
          style: {
            width: '120px',
            color: '#303336',
            backgroundColor: '#F8F8F8',
          },
        },
        {
          id: 1,
          name: '15',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 2,
          name: '30',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 3,
          name: '100',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 4,
          name: '不限',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 5,
          name: '不限',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
      ],
      b: [
        {
          id: 0,
          name: '可建功能',
          style: {
            width: '120px',
            color: '#303336',
            backgroundColor: '#F8F8F8',
          },
        },
        {
          id: 1,
          name: '30',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 2,
          name: '60',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 3,
          name: '300',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 4,
          name: '不限',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 5,
          name: '不限',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
      ],
      c: [
        {
          id: 0,
          name: '可建流程',
          style: {
            width: '120px',
            color: '#303336',
            backgroundColor: '#F8F8F8',
          },
        },
        {
          id: 1,
          name: '10',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 2,
          name: '30',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 3,
          name: '100',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 4,
          name: '不限',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 5,
          name: '不限',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
      ],
      d: [
        {
          id: 0,
          name: '使用人数',
          style: {
            width: '120px',
            color: '#303336',
            backgroundColor: '#F8F8F8',
          },
        },
        {
          id: 1,
          name: '10',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 2,
          name: '100',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 3,
          name: '600',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 4,
          name: '2000',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 5,
          name: '不限',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
      ],
      e: [
        {
          id: 0,
          name: '数据库',
          style: {
            width: '120px',
            height: '100px',
            color: '#303336',
            backgroundColor: '#F8F8F8',
          },
        },
        {
          id: 1,
          name: 'mysql',
          style: {
            width: '225px',
            height: '100px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 2,
          name: 'mysql',
          style: {
            width: '225px',
            height: '100px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 3,
          name: 'mysql、SqlServer、Oracle',
          style: {
            width: '225px',
            height: '100px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 4,
          name: 'mysql、SqlServer、Oracle、神通、巨衫',
          style: {
            width: '225px',
            height: '100px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 5,
          name: 'mysql、SqlServer、Oracle、神通、巨衫',
          style: {
            width: '225px',
            height: '100px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
      ],
      f: [
        {
          id: 0,
          name: '部署方式',
          style: {
            width: '120px',
            color: '#303336',
            backgroundColor: '#F8F8F8',
          },
        },
        {
          id: 1,
          name: '普通',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 2,
          name: '普通',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 3,
          name: '普通',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 4,
          name: '普通',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 5,
          name: '可集群',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
      ],
      h: [
        {
          id: 0,
          name: '场景',
          style: {
            width: '120px',
            height: '100px',
            color: '#303336',
            backgroundColor: '#F8F8F8',
          },
        },
        {
          id: 1,
          name: '小微企业/门店/学校/部门内部日常办公工具搭建',
          style: {
            width: '225px',
            height: '100px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 2,
          name: '企业中型系统搭建(采购系统、考试系统、报销系统等)',
          style: {
            width: '225px',
            height: '100px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 3,
          name: ' 中型企业信息化建设',
          style: {
            width: '225px',
            height: '100px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 4,
          name: '中大型企业阶段性信息化建设',
          style: {
            width: '225px',
            height: '100px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 5,
          name: '中大型企业战略性信息化建设',
          style: {
            width: '225px',
            height: '100px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
      ],
      i: [
        {
          id: 0,
          name: '开源率',
          style: {
            width: '120px',
            color: '#303336',
            backgroundColor: '#F8F8F8',
          },
        },
        {
          id: 1,
          name: '0%',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 2,
          name: '0%',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 3,
          name: '0%',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 4,
          name: '30%',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 5,
          name: '30%',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
      ],
      j: [
        {
          id: 0,
          name: '服务',
          style: {
            width: '120px',
            color: '#303336',
            backgroundColor: '#F8F8F8',
          },
        },
        {
          id: 1,
          name: '铜牌服务包',
          img: stars_t,
          sty: {
            fontSize: '18px',
            fontWeight: 'bold',
            color: 'rgba(229,144,118,1)',
          },
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 2,
          name: '铜牌服务包',
          img: stars_t,
          sty: {
            fontSize: '18px',
            fontWeight: 'bold',
            color: 'rgba(229,144,118,1)',
          },
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 3,
          name: '银牌服务包',
          img: stars_y,
          sty: {
            fontSize: '18px',
            fontWeight: 'bold',
            color: 'rgba(131,164,187,1)',
          },
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 4,
          name: '银牌服务包',
          img: stars_y,
          sty: {
            fontSize: '18px',
            fontWeight: 'bold',
            color: 'rgba(131,164,187,1)',
          },
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 5,
          name: '银牌服务包',
          img: stars_y,
          sty: {
            fontSize: '18px',
            fontWeight: 'bold',
            color: 'rgba(131,164,187,1)',
          },
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
      ],
      k: [
        {
          id: 0,
          name: '售价',
          style: {
            width: '120px',
            color: '#303336',
            backgroundColor: '#F8F8F8',
          },
        },
        {
          id: 1,
          name: '4980',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 2,
          name: '9980',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 3,
          name: '咨询',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 4,
          name: '咨询',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 5,
          name: '咨询',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
      ],
    },
    severPackdata: {
      a: [
        {
          id: 0,
          name: '资料',
          style: {
            width: '200px',
            color: '#303336',
            backgroundColor: '#F8F8F8',
          },
        },
        {
          id: 1,
          name: '文档/视频',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 2,
          name: '文档/视频/内部资料',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 3,
          name: '文档/视频/内部资料',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 4,
          name: '文档/视频/内部资料',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 5,
          name: '文档/视频/内部资料',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
      ],
      b: [
        {
          id: 0,
          name: '群服务',
          style: {
            width: '200px',
            color: '#303336',
            backgroundColor: '#F8F8F8',
          },
        },
        {
          id: 1,
          name: '✓',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 2,
          name: '✓',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 3,
          name: '✓',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 4,
          name: '✓',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 5,
          name: '✓',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
      ],
      c: [
        {
          id: 0,
          name: '入住客服系统',
          style: {
            width: '200px',
            color: '#303336',
            backgroundColor: '#F8F8F8',
          },
        },
        {
          id: 1,
          name: '',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 2,
          name: '✓',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 3,
          name: '✓',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 4,
          name: '✓',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 5,
          name: '✓',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
      ],
      d: [
        {
          id: 0,
          name: '服务时常',
          style: {
            width: '200px',
            color: '#303336',
            backgroundColor: '#F8F8F8',
          },
        },
        {
          id: 1,
          name: '',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 2,
          name: '3个月',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 3,
          name: '12个月',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 4,
          name: '12个月',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 5,
          name: '24个月',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
      ],
      e: [
        {
          id: 0,
          name: '处理工单数',
          style: {
            width: '200px',
            color: '#303336',
            backgroundColor: '#F8F8F8',
          },
        },
        {
          id: 1,
          name: '需付费',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 2,
          name: '30个/月',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 3,
          name: '40个/月',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 4,
          name: '50个/月',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 5,
          name: '不限',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
      ],
      f: [
        {
          id: 0,
          name: '工单响应时常',
          style: {
            width: '200px',
            color: '#303336',
            backgroundColor: '#F8F8F8',
          },
        },
        {
          id: 1,
          name: '',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 2,
          name: '60分钟',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 3,
          name: '30分钟',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 4,
          name: '10分钟',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 5,
          name: '实时',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
      ],
      h: [
        {
          id: 0,
          name: '远程平台搭建',
          style: {
            width: '200px',
            color: '#303336',
            backgroundColor: '#F8F8F8',
          },
        },
        {
          id: 1,
          name: '需付费',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 2,
          name: '1次',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 3,
          name: '3次',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 4,
          name: '3次',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 5,
          name: '不限',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
      ],
      i: [
        {
          id: 0,
          name: '现场培训',
          style: {
            width: '200px',
            color: '#303336',
            backgroundColor: '#F8F8F8',
          },
        },
        {
          id: 1,
          name: '需付费',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 2,
          name: '需付费',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 3,
          name: '需付费',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 4,
          name: '2天',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 5,
          name: '专家驻场',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
      ],
      j: [
        {
          id: 0,
          name: '升级服务',
          style: {
            width: '200px',
            color: '#303336',
            backgroundColor: '#F8F8F8',
          },
        },
        {
          id: 1,
          name: '需付费',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 2,
          name: '免费',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 3,
          name: '免费',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 4,
          name: '免费',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 5,
          name: '免费',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
      ],
      k: [
        {
          id: 0,
          name: '需求处理',
          style: {
            width: '200px',
            color: '#303336',
            backgroundColor: '#F8F8F8',
          },
        },
        {
          id: 1,
          name: '列队',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 2,
          name: '次优',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 3,
          name: '优先',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 4,
          name: '优先',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 5,
          name: '首选',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
      ],
      m: [
        {
          id: 0,
          name: '线下沙龙',
          style: {
            width: '200px',
            color: '#303336',
            backgroundColor: '#F8F8F8',
          },
        },
        {
          id: 1,
          name: '需付费',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 2,
          name: '免费',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 3,
          name: '免费',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 4,
          name: '免费',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
        {
          id: 5,
          name: '免费',
          style: {
            width: '225px',
            color: '#000',
            backgroundColor: 'rgba(255,255,255,1)',
          },
        },
      ],
    },
    severData: [
      {
        id: 13,
        imgUrl: serveImg01_1,
        title: '系统迁移',
        data: [
          '从JEPLUS迁移至JEPaaS',
          '价格根据工作量制定',
        ],
      },
      {
        id: 14,
        imgUrl: serveImg02_2,
        title: '售前协助',
        data: [
          '完成您公司售前工作中平台部分工作',
          '价格根据工作量制定',
        ],
      },
      {
        id: 1,
        imgUrl: serveImg01,
        title: '远程平台搭建',
        data: [
          '单机部署(150元)',
          '集群部署(2000元)',
          'saas部署(面议)',
        ],
      },
      {
        id: 2,
        imgUrl: serveImg02,
        title: '远程问题调试',
        data: [
          '每30分钟以内解决问题',
          '(50元)',
        ],
      },
      {
        id: 3,
        imgUrl: serveImg03,
        title: '复杂Demo制作',
        data: [
          '1500人/天',
        ],
      },
      {
        id: 4,
        imgUrl: serveImg04,
        title: '现场支持',
        data: [
          '差旅+住宿+人工费',
          '2000人/天',
        ],
      },
      {
        id: 5,
        imgUrl: serveImg05,
        title: '工单处理',
        data: [
          '每15分钟以内解决问题',
          '(50元)',
        ],
      },
      {
        id: 6,
        imgUrl: serveImg06,
        title: '系统转库处理',
        data: [
          '4000+',
        ],
      },
      {
        id: 7,
        imgUrl: serveImg07,
        title: '定制功能开发',
        data: [
          '1500人/天',
        ],
      },
      {
        id: 8,
        imgUrl: serveImg08,
        title: '代码质量测评服务',
        data: [
          '3000元/天',
        ],
      },
      {
        id: 9,
        imgUrl: serveImg09,
        title: '升级包服务',
        data: [
          '5000+',
        ],
      },
      {
        id: 10,
        imgUrl: serveImg10,
        title: '与三方系统接口对接',
        data: [
          '5000+',
        ],
      },
      {
        id: 11,
        imgUrl: serveImg11,
        title: 'UI制作',
        data: [
          '200+',
        ],
      },
      {
        id: 12,
        imgUrl: serveImg12,
        title: '培训服务',
        data: [
          '远程 2000人/天',
          '现场 差旅+住宿+人工费',
          '2000人/天',
        ],
      },
    ],
  },
  successfulDetail: {
    0: `
          <p class="title">大型金融企业内部办公管理系统</p>
          <p>金融科技席卷行业的浪潮之下，主动数字化转型、用科技重塑业务发展的新模式已成为头部券商的共识。而在实际的经营管理中，尤其是集团性的大型金融企业，常常面临这些问题：</p>
          <p>（1）公司业务规模庞大，员工及客户数量众多，在客户管理及业务划分上难以实现实时有效管理；</p>
          <p>（2）客户跟踪及业务流程复杂，对于客户及项目的把控无法做到精确跟踪；</p>
          <p>（3）业务难协同，涉及到业务环节的各个分公司、部门和员工在业务和项目的配合上无法做到全面及时协同。</p>
          <p>该企业作为中国证券行业长期、持续、全面领先的综合金融服务商，在致力于实现高质量增长、规模领先的同时，也正不断的顺应科技发展的趋势，依托数字化技术向布局线上服务、深化客户关系、注重专业服务转变。而对于这些同样存在于企业发展、业务开展和客户服务等层面的问题，利用数字化对客户管理系统的升级改造正是该企业的选择。</p>
          <p>基于数字化赋能机构业务的逻辑，JEPaaS针对该企业的客户管理和业务体系开发设计了该系统，帮助该企业建立了一个具备资源共享、协同管理、高效办公的数字金融服务平台。</p>
          <p class="subtitle">1、客户信息的综合管理：</p>
          <p>在使用该系统之后，无论是客户标准信息记录如：客户信息、跟进、合作、售后、服务、再次购买的记录，还是企业人员观察到客户的细节信息如：客户的行为习惯、兴趣爱好等记录，企业内部人员都可以录入到系统中，并可以根据客户特点进行分类、分级，从而对客户及客群的整体形象有着更全面的了解和判断。</p>
          <img src="${successfulDetail_0_1}" />
          <img src="${successfulDetail_0_2}" />
          <p class="subtitle">2、快速搜索数据查询：</p>
          <p>当客户数据录入到系统中，除了保存客户数据，还有一个重大用途，就是对客户数据进行查阅，企业各个部门在系统中可根据关键字搜索客户信息和业务点，就能查询到该客户的所有信息。快捷的数据查询不仅可以帮助企业人员对客户的快速了解，还可以增加新老客户的体验。</p>
          <img src="${successfulDetail_0_3}" />
          <p class="subtitle">3、权限管理：</p>
          <p>系统还赋予了每个员工单独的权限，权限不同，可进行的操作也不同。而每个员工也可独立进行业务相关的申请、审批；差异化的权限管理，在系统中也可以对数据共享进行分权限的设置，保证企业不同部门之间只能看到自己该看的数据。</p>
          <img src="${successfulDetail_0_4}" />
          <p class="subtitle">4、协同管理：</p>
          <p>从企业人员接触客户到不断获取和完善客户信息，并记录到系统时，可以对客户信息进行共享，再通过对业务流程的监控，按照各个关键点对人、部门进行有效追踪监控并实时跟进，保证了业务的进度和各个有关责任人的参与和协同处理，提高了业务效率。</p>
          <img src="${successfulDetail_0_5}" />
          <p>可以说，由JEPaaS开发的该客户管理系统，既符合了该企业实际营销工作的需要和现有操作习惯，又能覆盖行业价值客户业务特征，同时又兼顾使用的简单化、程式化和自动化，兼具实用性、适用性和使用效率三方面的性能，使该企业的客户营销和管理工作得到很大的提升。</p>
        `,
    1: `
          <p class="title">长沙某集团采购管理系统</p>
          <p>采购业务作为企业战略支出和运营管理的重要组成部分，在企业内部具有举足轻重的作用，可以说，采购部门是支持企业成长和成功的价值中心。尽管许多企业对采购管理系统较为重视，但传统的采购业务流程却存在管理复杂、效率低下、重复浪费等诸多问题。在数字化不断重塑市场环境与企业竞争的当下，数字化采购管理系统的开发使用无疑不是解决企业内的采购业务管理难题的最好解决方案。</p>
          <p>在“**集团采购管理平台”的项目中，凯特伟业利用自主研发的JEPaaS云平台，突破企业传统应用开发逻辑，仅凭由产品经理、开发人员和测试人员3人组成的微小团队，三周就完成了集团采购管理平台的搭建与上线。</p>
          <p>针对该集团需要实现内部相关部门和下属子、分公司的集中物资采购审批需求，统一管理采购和线上审批需求，并需要实现集团物资采购流程“看得见、管得着”，以及对于业务管理工作效率和管理水平提高的需求，凯特伟业设计了一套完善的采购管理方案，运用JEPaaS帮助该集团实现以下方面信息化管理：</p>
          <p class="subtitle">1、集中管理采购流程</p>
          <p>统筹管理集团及旗下各公司的采购需求，从需求申请、方案生成、预算审批、采购审定到部门协调、审批提醒、表单打印等整个流程，以及对采购计划的执行与监控，从源头上进行有效把控。</p>
          <img src="${successfulDetail_1_1}" />
          <img src="${successfulDetail_1_2}" />
          <p class="subtitle">2、灵活自助的管理模式</p>
          <p>采用自助服务平台，支持在线更新、自主管理，提高采购业务办理效率和管理效率，减轻集团的采购压力。</p>
          <img src="${successfulDetail_1_3}" />
          <p class="subtitle">3、严格的审批权限和反馈机制</p>
          <p>根据集团需要对各单位和部门进行相应的分类、分级，保证了严格的审批机制。</p>
          <img src="${successfulDetail_1_4}" />
          <p class="subtitle">4、移动端的便捷办公</p>
          <p>同步开发的移动端应用，保证了采购流程的随时审批与在线查看，使业务流程效率得以极大的提高。</p>
          <img src="${successfulDetail_1_5}" />
          <p>JEPaaS强大的产品特点、实干的开发能力、对于时间和人力成本的高控制性以及稳定可靠的系统表现都获得了一致好评。</p>
          <p>**集团在验收了由JEPaaS开发的采购管理平台之后，认为平台与集团的需求吻合度高，系统具有成熟的应用和管理机制，能够实现采购业务的全流程管理，提升对采购流程的管控能力。而JEPaaS强大的产品特点、实干的开发能力、对于时间和人力成本的高控制性以及稳定可靠的系统表现也都获得了该集团的一致好评。</p>
          <p>从“传统采购”向“数字采购”、“高效采购”、“阳光采购”的跨越式转变，JEPaaS的成功应用又再一次验证了JEPaaS快速开发平台的强大实力。未来，JEPaaS仍将继续携手更多企业共建数字化平台，为推动更多企业向数字化管理转型而不断努力！</p>
        `,
    2: `
          <p class="title">集团型能源企业设备管理系统</p>
          <p>企业信息化建设不断深入和发展, 系统的复杂性日益提高, 管理、控制和维护这些系统给企业的 IT 部门提出了新的挑战。在传统的企业管理中，由于组织设备种类繁多，数量庞大，运行位置分散，对设备的统一智能管理成为一种必然。</p>
          <p>对于能源巨头企业来说，设备管理系统不仅是企业内部的信息管理系统，更是连接企业内部各生产部门的桥梁与纽带，
          起着核心作用。JEPaaS针对企业的实际需求开发设计了一套智能设备管理系统，利用智能数据处理速度快、存储信息容量大，
          处理逻辑问题强、功能强大的优势，从企业管理特别是查询与决策信息的管理需求出发，针对性强，功能齐备，
          旨在通过帮助企业设备管理实现信息化促进设备管理工作的制度化、规范化，及时对设备进行状态更新，
          保证各项生产业务工作的正常开展，提高设备管理的工作效率、实现设备管理的集中控制、增强设备管理的流程衔接、
          降低设备管理的管理难度。</p>
          <img src="${successfulDetail_2_1}" />
          <p>借助JEPaaS云平台技术，相关集团管理系统以企业设备资产、设备台帐为基础，以检修记录(工单)的提交、审核、执行 为主线，以设备日常运行、保养为抓手，以风险评价、故障分析、状态检测为手段，以动静 电仪专业化管理为特色，以安全可靠、经济运行为目标，以智能监测预知维修为方向。运用系统提高设备可靠性和维修效率，降低维修成本，增强企业竞争力。</p>
          <img src="${successfulDetail_2_2}" />
          <img src="${successfulDetail_2_3}" />
          <img src="${successfulDetail_2_4}" />
          <img src="${successfulDetail_2_5}" />
          <p>该系统针对设备一生进行全过程管理，包括设备引进阶段的前期管理、采购管理、生产现场的使用管理、维护管理、故障管理、维修管理、备件管理、资产管理、技术改造管理等内容。同时建立一整套设备全生命周期的档案，形成随时可查的完整的设备档案。</p>
        `,
    3: `
          <p class="title">蒜瓣SCRM客户关系管理系统</p>
          <p>JEPaaS是专为SaaS而生的开发开发平台，自然在SaaS应用方面表现犹为出众。
          基于JEPaaS云平台开发的“轻云蒜瓣”企业级SaaS系列产品，其中，以蒜瓣SCRM为代表，自推出了市场，就凭借其卓越的表现，在市场上收获了热烈的反响。</p>
          <p>蒜瓣SCRM融合了国内顶级营销管理大师——孟昭春老师40多年的企业营销实战经验，是把当前最先进的销售管理理念和中国销售实践经验结合开发出的全新一代客户关系管理软件。更懂企业，更懂销售，更专业实用，蒜瓣SCRM为企业提供一站式全流程销售管理系统。</p>
          <img src="${successfulDetail_3_1}" />
          <p class="subtitle">蒜瓣SCRM特色亮点：</p>
          <p class="subtitle">1、销售展板全局速览</p>
          <p>蒜瓣SCRM提供了强大的销售展板功能，可展示企业每天、每周、每月、每季度、每年的销售概况，通过多维直观的数据统计分析展示，能帮助使用者和企业领导快速的了解掌握企业的销售情况，有助于销售政策的及时把握和决策分析。</p>
          <img src="${successfulDetail_3_2}" />
          <img src="${successfulDetail_3_3}" />
          <p class="subtitle">2、销售四连环</p>
          <p>蒜瓣SCRM销售四连环设立了目标一致的全员营销，赏罚分明的销售制度，通过四连环把企业内部与销售有关的人员都串联起来，协同销售合力促单，帮助企业在白热化的竞争中脱颖而出、快速签单。</p>
          <img src="${successfulDetail_3_4}" />
          <img src="${successfulDetail_3_5}" />
          <p class="subtitle">3、精准客户画像图</p>
          <p>蒜瓣SCRM精准而全面的客户画像图，能帮助销售及企业领导快速了解客户组织架构，摸清客户的底，找到影响成交的关键人物，知己知彼，才能百战百胜。</p>
          <img src="${successfulDetail_3_6}" />
          <p class="subtitle">4、销售工具箱</p>
          <p>蒜瓣SCRM销售工具箱包括一剑封喉话术、拒绝处理、销售锦囊、销售十八招等多个模块，针对销售话术、销售技巧、拒绝处理等进行标准化应对，销售菜鸟也能快速上手，找对人、说对话。</p>
          <img src="${successfulDetail_3_7}" />
          <p>蒜瓣SCRM不仅在销售管理上为企业提供强大的支持，而且在移动办公、沟通协同等方面的能力，也使得企业内部能够实现便捷高效的沟通协作，大幅提升组织效能，从而更加全面地推动业务进步。</p>
          <img src="${successfulDetail_3_8}" />
          <p>只有真正贴近客户的产品，才能为客户创造价值，这正是蒜瓣SCRM不变的宗旨。蒜瓣SCRM的成功应用也证明了JEPaaS在SaaS快速开发方面的实力，更好的为企业数字化赋能，JEPaaS在行动！</p>
        `,
    4: `
          <p class="title">高校数字校园系统应用</p>
          <p>教育信息化程度的高低已成为当今世界衡量各国、各地区教育发展水平的重要标志之一。数字校园建设则是教育信息化程度的具体体现，是校园信息化系统发展的必然，对于学校的管理和教学具有重要的意义。具体来说，数字校园的建设涉及到许多应用系统，如教务系统、校园一卡通系统、数字图书馆系统、校园监控系统等等，而在具体的开发应用部署中，多系统业务的融合是数字校园建设所面临的最大难题。</p>
          <p>对于**学院而言，其数字校园建设存在以下这些要求和特点：</p>
          <p>1)业务形式多样：涉及到教务系统、校园一卡通系统、数字图书馆系统、校园广播系统、信息发布系统、校园安全防范系统等多种业务形式；</p>
          <p>2) 数据类型多样：校园的数据有音视频流媒体、文件以及数据库等类型，需要统一数据标准；</p>
          <p>3) 业务量、数据量随时间日益增长：数字校园的监控、视频、邮件、电子图书馆等规模的扩展产生巨大的业务级数据量压力，对信息化建设提出性能更高、功能更强、容量更多、访问量更大的要求；</p>
          <p>4) 应用连续性、系统统一协作要求提升：随着学校内部的信息化建设深入，系统整合、数据共享、集中存储等对整体性能及功能要求更高。系统的建设需要数字校园信息化具有更加灵活可靠、高效稳定的架构和处理能力。</p>
          <p>在学院数字校园系统的建设中，JEPaaS整合多种应用系统需求，提供高效全面的开发工具和系统支持，为学院打造高度集成的高校信息系统提供了坚实的保障。</p>
          <p class="subtitle">1、云平台</p>
          <p>帮助学校建成了私有云平台，学校目前包含管理系统和实训平台在内一共33个系统，其中29个运行在云平台上，上云率达到88%。</p>
          <img src="${successfulDetail_4_1}" />
          <p class="subtitle">2、共享数据中心</p>
          <p>基于读写分离的分布式MYSQL集群，建设的共享数据中心底层。</p>
          <img src="${successfulDetail_4_2}" />
          <p class="subtitle">3、统一数据交换</p>
          <p>使用KETTLE，ODI等成熟的ETL工具建立统一的数据交换系统，进行数据清洗和转换，初步建成全校统一数据交换平台。</p>
          <img src="${successfulDetail_4_3}" />
          <p>数据交换调度监控中心，目前每天进行的交换作业30多个。</p>
          <img src="${successfulDetail_4_4}" />
          <p class="subtitle">4、教学改革平台</p>
          <p>建成贯穿课前、课中、课后的混合式教学平台，实现教学过程的动态化监控。</p>
          <img src="${successfulDetail_4_5}" />
          <p class="subtitle">5、强业务系统建设</p>
          <p>新建业务系统20余个，基本保证了每个业务部门都有自己的信息化系统，其中大部分都是通过JEPaaS开发扩展出来的。</p>
          <img src="${successfulDetail_4_7}" />
          <p class="textCenter">学籍管理</p>
          <p class="subtitle">6、数据分析和预警</p>
          <p>帮助实现学校、专业、课程、教师、学生五个层面的监测预警及数据分析体系的建立，辅助部门和校领导更高效的决策和管理。</p>
          <img src="${successfulDetail_4_11}" />
          <p>数字校园系统的建设，大幅提高了学校的办公效率，为校园的统一管理提供了极大方便。而JEPaaS为数字校园建设提供可靠的开发平台，在优化校园内整体信息系统以满足教育行业的飞速发展及教育教学改革需要的同时，也为该校今后数字校园的持续发展打下了坚实的基础。</p>
        `,
    5: `
          <p class="title">集团型制造企业信息管理工作系统</p>
          <p>该集团是在国内首趋一指的以绿色钢结构系统与金属围护系统为主导产业的综合服务集团。作为一个典型的大型集团公司，多维不仅在国内全国许多地区都设有研发基地和子公司，而且在国外多个国家设有海个公司，产品行销全球50多个国家与地区，年销售收入逾50亿元，海外收入超20亿元。</p>
          <img src="${successfulDetail_5_1}" />
          <p>在与JEPaaS的合作中，基于JEPaaS云平台，该集团的信息化管理与工作系统主要从以下几个方面来进行部署：</p>
          <p class="subtitle">1、客户管理CRM系统</p>
          <p>包括客户管理，订单及合同流程及管理，销售计划及库存，以及市场分析等。与传统开发部署不的同是，JEPaaS云平台经过多年沉淀，以业务为核心逻辑来以实现各项功能。也就是说，传统开发的根基更多是基于技术，而JEPaaS云平台开发则是基于业务习惯和逻辑，这也是JEPaaS突出的特色之一。</p>
          <p class="subtitle">2、CRM与原有ERP集成</p>
          <p>同时，还要保证新搭建的CRM系统与原有ERP实现集成贯通，包括基础信息集成、业务数据集成，以及财务数据集成等。JEPaaS在这个操作应用方面独具优势，它有很强的普适性，能快速兼融，与其他系统实现共融共通。</p>
          <p class="subtitle">3、实现PC与移动办公互通</p>
          <p>包括实现微信办公、公众号、企业号、APP、图形报表、SAAS、H5等，这些都可以快速在JEPaaS里轻松实现，并且满足多种个性化需求。</p>
          <p>通过JEPaaS云平台搭建的信息化管理与工作系统，帮助该集团实现来企业数字化的高效管理，大大提高了办公效率及流程管理效率，为多维树立业界标杆级龙头地位提供了有力助益。</p>
        `,
  },
};
export default mock;
